<template>
  <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.319534 17.1451H0.19519V14.2457L5.35546 8.8424L0.19519 3.3073V0.407959H0.319534L7.15845 7.91988V9.76492L0.319534 17.1451Z" fill="currentColor"/>
    <path d="M9.8973 17.1451H9.77295V14.2457L14.9332 8.8424L9.77295 3.3073V0.407959H9.8973L16.7362 7.91988V9.76492L9.8973 17.1451Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDoubleChevronRight'
}
</script>
